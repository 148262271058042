.banner {
  border: 2px solid rgb(255, 0, 68);
  /* margin-top: 75px; */
  /* padding: -20px; */
}

.home-dev {
  /* border: 2px solid yellow; */
  /* margin: 0px -10px 0px -10px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-bg {
  background-color: rgb(0, 0, 0);
  box-shadow: 0px 10px 50px rgba(10, 10, 10, 0.94);
  /* background-image: '../../Utilities/Images/gasTurbine.gif'; */
}

.BannerIntro-Div {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  text-align: center;
  box-shadow: 0px 10px 100px rgba(128, 128, 128, 0.853);


}

.bannerintro-innerDiv {
  background-color: rgba(128, 128, 128, 0.116);
  padding-top: 10px;
  padding-bottom: 10px;
}


.fancy-box {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;


}

.fancy-box:hover {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
  transform: translateY(-5px);
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  75% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.fancy-box-with-animation {
  animation: wiggle 1s infinite;
}




.introdescription {
  position: relative;
}

.introdescription span {
  display: inline-block;
  position: relative;
  transition: transform 0.5s ease-out;
}

.introdescription span::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #ffcc00;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease-out;
}

.introdescription:hover span::before {
  transform: scaleX(1);
  transform-origin: right;
}

.introdescription:hover span {
  transform: translateX(5px);
}



.procees-div {
  /* border: 2px solid red; */
  color: white;
  background-color: rgb(4, 1, 22);
  padding-top: 50px;
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 5px; */
}

@keyframes fly-in {
  0% {
    transform: translateY(-50%);
    opacity: 1;
  }

  100% {
    transform: translateY(0px);
    transform: translateX(500px);
    opacity: 1;
  }
}

@media screen and (min-width: 600px) {
  .flying-text {
    animation-name: fly-in;
    animation-duration: 3s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
}

@media screen and (max-width: 599px) {
  .flying-text {
    opacity: 1;
  }
}






.flip {
  perspective: 1000px;
  transition: transform 0.7s;
  padding-left: 20px;
  padding-bottom: 30px;
}

.flip:hover {
  transform: rotateY(180deg);
}

.flip .front,
.flip .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.flip .front {
  z-index: 2;
}

.flip .back {
  transform: rotateY(180deg);
}


.RnD-section {
  /* background-color: black; */

  background-image: url('../../Utilities//Images/WASP_CFD_1w.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #ffcc00; */

  /* width: 100%;
  height: 100%; */
}

.RnD-div {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 3px Solid red; */
  color: white;
  /* background-color: rgba(0, 0, 0, 0.46); */
}

.cardCarousel-descriptions {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  padding: 30px;
  /* color: navy; */
}

.RnD-innerdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.46);
  box-shadow: 0 0 20px 10px rgba(239, 237, 223, 0.734);
  /* border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; */
  padding: 20px;

}

.title-text {
  color: greenyellow;
  font-weight: 800;
}

.title-text:hover {
  font-size: xx-large;
}

.latest-blog {
  /* border: 2px solid red; */
}


.about-intro {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 50px; */
  background-color: rgba(255, 255, 255, 0.047);
  color: white;

  margin-top: 10px;
  /* padding-top: 100px; */
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  overflow-y: scroll;
  top: 10px;



}

.about-img {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.card-Carousel {
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(128, 128, 128, 0.359); */
}


.contact-form {
  padding: 20px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid red; */

}









.sections {
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid green; */
}

.section {

  /* padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  /* border: 2px solid red; */

}

.Homeabout-container {
  /* border: 2px solid red; */
  margin-top: 100px;
  margin-bottom: 100px;
  background: linear-gradient(60deg, #011110, #06313c);
  /* Simple box gradient */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}



.section img {
  border: 2px solid white;
  width: 160px;
  height: 160px;
}

.courses {
  /* border: 2px solid red; */
}

.event-section {

  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid red; */

}

.contact-details {
  padding: 20px;
}

.home-course-Card {
  display: flex;
  align-items: center;
  justify-content: center;

}

.sections-FAQ {
  /* border: 5px solid green; */
  background-color: rgba(23, 77, 85, 0.113);
  border-radius: 10px;
  margin-bottom: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.Home-FAQ {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 30vh;
  max-height: 50vh;
  overflow-y: scroll;
  background-color: rgba(6, 1, 1, 0.109);
  padding: 20px 0 0;
  /* Add padding at the top */
}

.Accordion.Item {
  background-color: rgba(255, 255, 255, 0.19);
  /* Transparent white background */
  /* Add any other styles as needed */
}


.FAQ-Title {
  text-align: center;
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase;
  color: #3498db;
  text-shadow: 2px 2px 0 #2980b9, 4px 4px 0 #2980b9;
}

.text-3d::before {
  content: attr(data-text);
  position: absolute;
  top: 2px;
  left: 2px;
  color: #e74c3c;
  transform: perspective(400px) rotateY(10deg);
}















.contact-sections {
  /* border: 2px solid red; */
  color: white;
  background-color: rgb(4, 4, 53);

}



.contact-form label {
  padding: 10px 0 10px 20px;
}

.contact-form label span {
  border: 2px solid green;
  margin: 10px;
  padding: 5px
}


.contact-form label input {
  padding: 10px 0 10px 20px;
}

.event-Button {
  color: white;
  margin: 10px 0 10px 0;
}

.event-Button:hover {
  color: black;
}

.enrollCard {
  display: flex;
  align-items: center;
  justify-content: center;
}