.carouselItems {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid gray;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    
}

.carouselItem {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid green; */
    /* width: 180px; */
}

.carouselItem img {
    /* width: 160px; */
    /* border: 2px solid red; */
    ;
}