body {
  margin: 0;
  padding: 0;
  background-color: black;
  /* border: 5px solid red; */



  /* Firefox */

}


.Routes-div::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}


.App {
  text-align: center;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* border: 5px solid red; */
}

.header-row {
  /* border-top: 1px solid red; */
  height: 10vh;
}

.footer-row {}

.App-link {
  color: #61dafb;
}

.app-header {
  /* position: sticky; */
  position: relative;
}


.Routes-div {
  /* margin-top: 150px; */
  /* margin-bottom: 10px; */

  height: 85vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* border: 2px solid rgb(17, 0, 128); */
  position: absolute;
  top: 75px;

}

.footer-app {


  /* border: 2px solid red; */
  height: 15vh;

}

.footer-app {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #333;
  /* You can change the background color as needed */
  color: #fff;
  /* Text color for the footer */
  padding: 10px;
  /* Adjust the padding to your liking */
  text-align: center;
  /* Center-align the content within the footer */
  z-index: 999;
  /* Ensure the footer appears above other content */
}