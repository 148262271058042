.event-all {
    height: 80vh;

    /* margin-top: 50px */
}

.card-body {
    height: 250px;
    overflow-y: scroll;
    /* border: 3px solid green; */

}

iframe {
    text-align: justify;
}

