.about-container {

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    /* border: 2px Solid green; */
    /* background-color: #3E4860; */
    color: white;

    background: linear-gradient(45deg, #011f1d, #010f13);
    /* Simple box gradient */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

}

.details {
    /* margin-top: 20px; */
    margin-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.047);
    /* box-shadow: 0px 0px 20px 2px white; */
    padding: 20px;
    text-align: justify;
    /* border-radius: 10px; */
    margin-bottom: 100px;
}

.About-header {
    text-shadow: 4px 4px 4px rgba(235, 239, 233, 0.573);
    transform: perspective(300px) rotateX(40deg);

}

.about-logo {
    margin-top: 100px;
    padding-left: 80px;



}

.about-logo a {
    text-decoration: none;
    margin: 5px;
    color: white;
}

.founder-details {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.banner-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: rotateY(45deg);
    transform-style: preserve-3d;
    animation: spin 10s linear infinite;
    margin-bottom: 50px;
}

.banner-img img {
    height: 40vh;
    width: 70%;

}

@keyframes spin {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}