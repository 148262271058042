.login-form {
    width: 300px;
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius:20px;
    box-shadow: 3px 3px 3px 2px rgba(122, 114, 114, 0.295);
    /* border: 2px solid blue; */
}
.Specialize-div{
    border: 2px solid blue;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    position:sticky;

}