body {
    background: linear-gradient(90deg, #01071e, #6ad4ef);
    overflow-y: scroll;
    margin-bottom: 100px;
}

.CA-Container {
    /* border: 5px solid rgba(7, 67, 67, 0.735); */
    padding-bottom: 50px;
    height: 65vh;
    overflow-y: scroll;

}

.CA-ProfileCard {
    display: flex;
    align-items: center;
    justify-content: center;
}