.userProfile {
    position: relative;

}

.user-form {
    /* margin: 20px; */
    margin-top: 10px;
    border: 2px Solid gray;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 5px 0 10px 2px rgba(0, 0, 0, 0.625);
    display: flex;
    align-items: center;
    justify-content: center;

}