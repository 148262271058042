.feed-container {
    margin: 0 10px 0 10px;
    height: 50vh;
    overflow: scroll;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    background-color: rgba(240, 248, 255, 0.133);
    /* box-shadow:-10px -5px black; */

    /* border: 2px solid red; */
}

.latest-feed {
    border: 2px solid rgb(5, 97, 54);
    
    color:black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feed-container::-webkit-scrollbar {
    display: none;
}