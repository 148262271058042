.multi-carousel .carousel-indicators li {
    background-color: #ddd;
    border-color: #ddd;
}

.multi-carousel .carousel-indicators .active {
    background-color: #007bff;
    border-color: #007bff;
}

.multi-carousel .carousel-control-prev,
.multi-carousel .carousel-control-next {
    width: 3%;
}

.multi-carousel .carousel-control-prev-icon,
.multi-carousel .carousel-control-next-icon {
    height: 100%;
    transform: translate(0, -50%);
    top: 50%;
    width: 100%;
}

.multi-carousel-card {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    margin: 20px;
    width: 300px;
}

.multi-carousel-card .card-img-top {
    height: 200px
}