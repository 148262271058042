.EnrollModal {
    background-color: rgba(4, 51, 60, 0.807);
    color: white;
    border: 2px solid cyan;
}

.enrollCard-succcess-modal {
    background-color: black;
    color: white;
    border: 2px solid cyan;
}
.Course-card{
    
}