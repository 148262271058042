.container-coursedescription {
    background: linear-gradient(45deg, #011f1d, #010f13);
    /* Simple box gradient */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* Add a shadow for depth */



}

.course-intro {
    margin-top: 50px;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    background-color: rgba(255, 255, 255, 0.047);
    /* margin-left: 20px; */
    border-radius: 10px;
    color: rgba(255, 255, 255, 0.635);

}

.ProgramToLearn {
    color: cyan;
    font-weight: 500;
}

.course-inntoimg {
    display: flex;

    justify-content: center;
    align-items: center;
    margin-top: 50px;
    /* border: 2px solid red; */
    /* margin-bottom: 50px; */
}

.intro-image {
    /* border: 2px solid green; */

}

.corseOverview-container {
    margin-top: 100px;
    background-color: rgba(255, 255, 255, 0.047);
    color: rgba(255, 255, 255, 0.635);
    border-radius: 10px;
}

.course-overviewBangla {
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 20px;

}

.course-description-Bangla {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.intro-image2 {
    width: 20%;
    text-align: center;
    color: white;
}

.container-programimg {
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;

}

.course-inntoimg {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid red; */

}

.container-journey {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.modelues-container {
    margin-top: 100px;
    background-color: rgba(255, 255, 255, 0.047);
    color: rgba(255, 255, 255, 0.73);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    Padding: 30px;
    margin-bottom: 50px;

}

.course-benefites {
    margin-top: 100px;
    background-color: rgba(255, 255, 255, 0.047);
    color: rgba(255, 255, 255, 0.73);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    Padding: 30px;
    margin-bottom: 50px;
    /* border: 2px solid red; */

}



.timeline {
    position: relative;
    border: 3px solid rgb(5, 78, 78);
    height: 40vh;
    overflow: scroll;
    border-radius: 10px;
}

.timeline-item {
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
    position: relative;
}

.circle {
    width: 20px;

}

.line {
    /* position: absolute; */

}

.content {
    flex: 1;
}

.event-title {
    font-weight: bold;
    /* Make the event title bold */
}

.date {
    color: rgba(255, 255, 255, 0.73);
    /* Date text color */
    /* margin-top: 5px; */
}

.description {
    /* margin-top: 5px; */
    color: rgba(255, 255, 255, 0.73);
    ;
    /* Description text color */
}

.FAQ-Cdeatils {

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    background-color: rgba(255, 255, 255, 0.047);
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 100px;


}

.accordian-faq {
    /* border: 2px solid red; */
    background-color: rgba(0, 0, 0, 0.183);
    color: white;
}

.FAQ-Bangla {
    background-color: black;
}

.accordian-body {
    height: 40vh;
    overflow-y: scroll;
    padding: 20px;

    margin-bottom: 50px;
    margin-bottom: 20px;
}



.accordian-header {}

.course-Brochure {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-frame {
    width: 300px;
    /* Adjust the width as needed */
    /* height: 300px; */
    /* Adjust the height as needed */
    padding: 10px;
    /* Adjust the padding as needed */
    border: 2px solid #ccc;
    /* Border color */
    border-radius: 10px;
    /* Border radius for rounded corners */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.327);
    /* Box shadow for a subtle shadow effect */
    background-color: rgba(255, 255, 255, 0.153);
    /* Transparent background color */
}

.image-frame img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}