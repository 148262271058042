.header {
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.logo{

    
}

.logo img{
    height: 8vh;
    
}
