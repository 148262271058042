.tree-view {
    position: relative;

}

.line {
    position: absolute;
    top: 0;
    left: -15px;
    width: 15px;
    height: 100%;
    border-left: 1px solid #c8c810;


}

.blog-container {
    margin-top: 20px;
}

.line-vertical {
    top: 50%;
    height: 50%;
    /* border: 2px solid red; */
}

.folder-icon {
    cursor: pointer;
    /* border: 2px solid red; */
}

.content-title {
    border: 2px solid rgba(55, 56, 54, 0.798);
    cursor: pointer;
    background-color: rgba(128, 128, 128, 0.111);
    margin-top: 5px;

    /* box-shadow: 0 3px 5px 5px rgba(28, 6, 54, 0.213); */
}

.tree-content {
    /* border: 2px solid red; */
    border-right: 2px solid rgba(128, 128, 128, 0.728);
    border-style: groove;
    padding: 5px;
    /* height: 70vh; */
}

.content-details {
    /* border: 2px solid rgb(0, 255, 81); */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;

}

.author-card {}

.blogs-details{
    /* border: 2px solid red; */
  margin: 30px;
}

.blogs-container{
    /* border:2px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    
}