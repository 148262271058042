.videoplayers {
    /* border: 2px solid orange; */
    /* margin-top: 60px; */

}

.videoplay-list {
    /* margin-top: 60px; */
    margin-bottom: 50px;
    /* border: 2px solid red; */
    height: 65vh;
    overflow-y: scroll;
    padding-top: 10px;
    padding-bottom: 30px;
    border: 2px solid rgb(11, 151, 153);
    /* Outer border color */
    position: relative;
}

.videoplay-list:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: linear-gradient(45deg, #01071e, #71e3ff);
    /* Gradient background */
    z-index: -1;
    border: 3px solid #045357;
    /* Inner border color */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    /* Box shadow for shading */
}

.list-group-item {
    font-weight: 'bold';
    /* border: 2px solid red; */
}

.Module-title {
    /* border: 2px solid red; */
    font-weight: bold;

}
.main-modules{
    /* border: 2px solid red; */
    font-weight:800;
    font-size: 15px;
}