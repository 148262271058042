.signUp-form {
    position: relative;

    height: 100%;
    background-image: url(../../Utilities/Images/signup3.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #3E4860;
    box-shadow: 0 3px 10px 10px #3E4860;

}

.signUp {
    /* border: 2px solid green; */


    color: white;
    background-color: rgba(0, 0, 0, 0.182);
    position: relative;
    height: 100%;
    /* border-radius: 10px; */
    border: 2px solid white;
    border-radius: 10px;
    box-shadow: 0 3px 5px 3px white;
    border: 2px solid red;
    overflow-y: scroll;
    margin-bottom: 50px;

}


@keyframes fancyTextAnimation {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    50% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes colorChangeAnimation {
    0% {
        color: violet;
    }

    25% {
        color: blue;
    }

    50% {
        color: white;
    }

    75% {
        color: orange;
    }

    100% {
        color: purple;
    }
}

.SignUp-title {
    display: inline-block;
    animation: fancyTextAnimation 10s infinite, colorChangeAnimation 5s infinite;
    /* font-size: 48px; */
    color: #fff;
    text-shadow: 2px 2px 0 #000, 4px 4px 0 #888;
}