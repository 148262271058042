.player-wrapper {
  position: relative;
  padding-top: 100%;
  /* 720 / 1280 = 0.5625 */
  border: 2px solid red;
  width: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.video-player-container {
  position: relative;
  /* padding-top: 56.25%; */
  display: flex;
  justify-content: center;
  position: relative;
  /* border: 2px solid greenyellow; */
  pointer-events: all;
}



.video {
  width: 350px;
  /* height: auto; */
  border: 3px solid rgb(11, 151, 153);
  box-shadow: 2px 5px 10px 2px rgb(26, 2, 2);
  border-radius: 10px;
}

/* Videoplayer.css */
.username-float {
  position: absolute;
  top: 10px;
  /* Adjust the top position as needed */
  left: 10px;
  /* Adjust the left position as needed */
  background-color: rgba(0, 0, 0, 0.7);
  /* Background color with some transparency */
  color: white;
  /* Text color */
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.video-player-container {
  position: relative;
}

.username-float {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-80%, -80%);
  background-color: rgba(0, 0, 0, 0.047);
  color: rgba(227, 17, 17, 0.264);

  font-size: 16px;
  animation: floatText 10s linear infinite;
  /* Adjust animation duration as needed */
}

@keyframes floatText {

  0%,
  100% {
    transform: translate(-50%, -50%) translateY(0);
  }

  50% {
    transform: translate(-50%, -50%) translateY(-20px);
    /* Adjust vertical distance */
  }
}

.video-player-container {
  position: relative;
}

.iframe-container {
  position: relative;

  border: 2px solid red;
  pointer-events: all;

}

.youtube-iframe {
  width: 120%;
  height: 150%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 10%;
  width: 62%;
  height: 70%;
  background: transparent;
  pointer-events: all;
  /* Allow click-through to elements behind the overlay */
  z-index: 2;
  /* Ensure the overlay is above the iframe and other elements */
  /* border: 2px solid red; */
}

.hiding-corner {
  margin-top: 50%;
  margin-left: 55%;
  margin-right: 20%;
  width: 30%;
  height: 30%;

}

/* Add these styles to your Videoplayer.css file */
.progress-bar {
  position: relative;
  height: 10px;
  width: 100%;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar div {
  position: absolute;
  height: 100%;
  background: linear-gradient(to right, #4caf50, #2196f3);
  transition: width 0.3s ease-in-out;
  border-radius: 5px;
}

.progress-bar .time-indicator {
  position: absolute;
  top: -30px;
  transform: translateX(-50%);
  white-space: nowrap;
  color: #fff;
  font-size: 12px;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.2500%;
  box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
  margin-top: 1.6em;
  margin-bottom: 0.9em;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid red; */
}


.video {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  margin: 0;
  object-fit: cover;
  border: 2px solid gold;
  /* Maintain aspect ratio */
}


.floating-username {
  position: absolute;
  top: 20%;
  color: rgba(174, 10, 10, 0.327);
  left: 0;
  /* Start from the left */
  transform: translate(-50%, -50%);
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  pointer-events: none;
  animation: floatAnimation 20s linear infinite;
  /* 5s duration, linear easing, infinite loop */
}

@keyframes floatAnimation {
  0% {
    left: 0;
  }

  50% {
    left: 70%;
    /* Move to the right */
  }

  100% {
    left: 0;
    /* Back to the left */
  }
}