.MDtitle-dev {
    margin-top: 50px;
    margin-bottom: 50px;
    ;
}

.Course-features-Container {
    background-color: gray;
    padding: 30px;
    background-image: url(../../../Utilities/Images/2.PNG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 50px;
    margin-top: 50px;
    height: 50vh;
    overflow-y: scroll;


}

.Course-features {
    background-color: rgba(0, 0, 0, 0.59);
    color: white;
    border: 2px solid rgba(128, 128, 128, 0.935);

}

.info-container {
    border: 2px solid rgba(255, 0, 0, 0.41);
}

.Course-features-title {
    color: gray;
    border: 2px solid white;
    text-align: center;
    background-color: black;
    padding: 5px 0 5px 0;
    border-radius: 5PX;
}

.Course-features-title:hover {
    color: white;
    font-weight: bold;
}

.feture-Image {
    /* border: 2px solid red; */
    position: sticky;
    top: 30px;
}

.course-info {
    border: 2px solid grey;
    background-color: rgba(0, 0, 0, 0.88);
    font-weight: 300;
    color: white;
    width: 350px;
}


::-webkit-scrollbar {
    width: 12px;
    /* Width of the scrollbar */
}

/* Customize the scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the scrollbar thumb */
    border-radius: 6px;
    /* Rounded corners */
}

/* Customize the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Color of the scrollbar thumb on hover */
}

/* Customize the scrollbar track on hover */
::-webkit-scrollbar-track:hover {
    background-color: #f0f0f0;
    /* Color of the scrollbar track on hover */
}

.course-objectives {
    margin-bottom: 150px;
    padding: 50px;
}

