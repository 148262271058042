.players-div{
    /* border: 1px solid red; */
    padding: 20px 0 20px 0;
    background-color: rgba(3, 43, 78, 0.493);
    border-radius:20px;

}

.TopPlayerCarousel-items{
    /* border: 2px solid white; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding:20px;
}