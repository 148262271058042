.course-overview {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;

    /* border: 2px solid red; */
}

.course-Description {
    text-align: justify;
    padding: 10px;
}

.Brochure-image Image {
    /* border: 2px solid red; */
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 8px;

}

.brochure-div {
    height: 78vh;
    overflow-y: scroll;
    position: relative;
    display: inline-block;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

}