.signin-Container {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../Utilities/Images/loginform.png);
    height: 90vh;
    /* Use 100vh to set the height to 100% of the viewport height */
    display: flex;
}

.signin-form {
    /* background-size: contain; */


    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgba(16, 15, 15, 0.425);
    box-shadow: 0 5px 10px 20px rgba(0, 0, 0, 0.416);


}

.signin-box {
    border: 2px solid rgb(17, 12, 12);
    background-color: rgba(0, 0, 0, 0.367);
    color: White;
    border-radius: 5px;
    padding: 10px;
}