@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');



.detailsProfile {
    margin-top: 0;
    /* border: 2px solid red; */
    /* border: 2px solid red; */
    margin-bottom: 100px;
    overflow-y: scroll;



}

.detailsProfile div {
    /* margin-top: 100px; */
    /* border: 2px solid rgb(119, 0, 255); */
}

.profile-Cover {
    background-image: url('../../../../Utilities/Images/coverpic1.png');
    background-repeat: no-repeat;
    background-size: cover;
    /* width: 100%; */
    height: 13rem;
    /* border: 2px solid green; */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.6);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 20px;

}

.userName {
    font-family: 'Pacifico', cursive;
}

.userTitle {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    color: orangered;
}

.Personal-info {
    /* border: 2px solid white; */
    /* background-color: rgba(255, 255, 255, 0.077); */
    /* color: rgba(128, 128, 128, 0.886); */
    /* padding: 10px; */
}

.Profile-Intro {
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

}

.Profile-Summary {
    /* background-color: aquamarine; */
    margin-left: 30px;
    border-left: 1px solid gray;

}

.info-div {}

.about-user {
    /* border: 1px solid gray; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    margin-bottom: 50px;


}

.aboutuser-column {
    /* border: 2px solid goldenrod; */
    background-color: black;
    color: rgb(128, 128, 128);
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.6);
}

.ProfileDetails {
    background-color: black;
    color: white;
    border: 3px solid rgb(195, 12, 12);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.6);
}

.ProfileDetails li {
    padding: 5px;
}

.profile-headers div:hover {
    font-weight: 700;
}

.aboutuser-column:hover {
    color: white;
}