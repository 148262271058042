.CA-regContainer {
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    overflow-y: scroll;
}

.CA-Form {
    /* margin-top: 50px; */
    border: 2px solid cyan;
    color: white;
    background-color: rgba(7, 57, 57, 0.672);
    padding: 20px;
    border-radius: 10px;

    /* width: 90%; */

    /* margin-bottom: 100px; */
}

.modal-transparent-bg {
    background: rgba(7, 57, 57, 0.726);
    color: white;
    border: 4px solid rgb(69, 171, 171);
    /* Adjust the opacity as needed */
}