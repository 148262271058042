.navbar {
    background-color: #fff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .navbar-logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .navbar-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 4.5rem;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }
  
  .navbar-links {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    list-style: none;
    margin: 0;
  }
  
  .navbar-links li {
    margin-bottom: 1rem;
  }
  
  .navbar-links a {
    text-decoration: none;
    color: #333;
    transition: color 0.2s ease-in-out;
  }
  
  .navbar-links a:hover {
    color: #555;
  }
  
  .navbar-burger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1.5rem;
    height: 1.25rem;
    cursor: pointer;
  }
  
  .navbar-burger-line {
    width: 100%;
}