.course-reg {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid red; */
    /* margin-top: 10px; */
    /* padding: 20px; */
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.141);
    color: white;
    padding: 20px;
    
}

.course-regContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3E4860;
    height: 80vh;

}

.course-form {
    /* border: 3px solid lightgreen; */

    padding: 10px;
}