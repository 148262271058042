.display-Materials {

    display: block;
    align-items: center;
    margin-bottom: 50px;
}

.display-Materials a span {
    margin-bottom: 20px;
    color: white;


}